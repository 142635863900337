import { default as indexiLDUNLQ0YUMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/index.vue?macro=true";
import { default as packagesj9kML2XSHpMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/packages.vue?macro=true";
import { default as partsVDJ1HHc4n3Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/parts.vue?macro=true";
import { default as customBSimkTLAXRMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom.vue?macro=true";
import { default as indexuU6jFlOEpXMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/index.vue?macro=true";
import { default as ratesAaLnrWok0RMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/rates.vue?macro=true";
import { default as subscriptionDoAiwoOS2vMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/subscription.vue?macro=true";
import { default as accountgr6yTGVAEOMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account.vue?macro=true";
import { default as _91id_93eMDO3XCsxJMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexk34ny0g0GeMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93UHNCwt33LmMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93r3xA3I1oIXMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/[id].vue?macro=true";
import { default as indexMtsoKOoqKYMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculeswTU8SvroEtMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/vehicules.vue?macro=true";
import { default as _91id_93_45_91title_93a1751e8YjkMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/[id]-[title].vue?macro=true";
import { default as entretiendRnUuEpconMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/entretien.vue?macro=true";
import { default as indexw2NMRuFMKdMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/index.vue?macro=true";
import { default as outillageoCJ6N4Ro9GMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/outillage.vue?macro=true";
import { default as indexKAtX0rj0RdMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/index.vue?macro=true";
import { default as manualsRMOGVXnrE4Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/manuals.vue?macro=true";
import { default as timesep1LpW6uFRMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/times.vue?macro=true";
import { default as catalogild2myZ1cCMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog.vue?macro=true";
import { default as commandeMVtbneeu4UMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/commande.vue?macro=true";
import { default as _91tab_938q5OiaEMU0Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_93CtmdTtek4AMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93Kbl5hU3wSaMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdf65y5ECdiFqMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/imprimer-pdf.vue?macro=true";
import { default as index6jTbIooIAIMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/index.vue?macro=true";
import { default as loginZdvs496Ue3Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/login.vue?macro=true";
import { default as panieruBBqC84Vm6Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/panier.vue?macro=true";
import { default as indexKObWeJUhfwMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/index.vue?macro=true";
import { default as ListekQ0J3NRo1HMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/Liste.vue?macro=true";
import { default as listing6u81K0wHBvMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/listing.vue?macro=true";
import { default as productsjx7DmauiffMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/products.vue?macro=true";
import { default as tableau_45de_45bordTI3PjycTFsMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountgr6yTGVAEOMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: customBSimkTLAXRMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom.vue").then(m => m.default || m),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/index.vue").then(m => m.default || m)
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/packages.vue").then(m => m.default || m)
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/parts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/rates.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/subscription.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/show/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/[id].vue").then(m => m.default || m)
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/index.vue").then(m => m.default || m)
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/vehicules.vue").then(m => m.default || m)
  },
  {
    name: "Boutique-id-title",
    path: "/Boutique/:id()-:title()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/[id]-[title].vue").then(m => m.default || m)
  },
  {
    name: "Boutique-entretien",
    path: "/Boutique/entretien",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/entretien.vue").then(m => m.default || m)
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/index.vue").then(m => m.default || m)
  },
  {
    name: "Boutique-outillage",
    path: "/Boutique/outillage",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/outillage.vue").then(m => m.default || m)
  },
  {
    name: catalogild2myZ1cCMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/manuals.vue").then(m => m.default || m)
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/times.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/commande.vue").then(m => m.default || m)
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/[tab].vue").then(m => m.default || m)
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/show/[id].vue").then(m => m.default || m)
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdf65y5ECdiFqMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/imprimer-pdf.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginZdvs496Ue3Meta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/panier.vue").then(m => m.default || m)
  },
  {
    name: "pneumatique",
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/index.vue").then(m => m.default || m)
  },
  {
    name: "pneumatique-Liste",
    path: "/pneumatique/Liste",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/Liste.vue").then(m => m.default || m)
  },
  {
    name: "pneumatique-listing",
    path: "/pneumatique/listing",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/listing.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/tableau-de-bord.vue").then(m => m.default || m)
  }
]