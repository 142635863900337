
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "entity": {
    "id": 3,
    "name": "EIT",
    "uniqueReference": "EIT",
    "siret": "00000000",
    "creationDate": null,
    "isActivated": true,
    "isDisabled": false,
    "deletedDate": null
  },
  "establishment": {
    "id": 20,
    "entityId": 3,
    "templateId": 1,
    "themeId": 4,
    "simultaneousUsersConnexion": 4,
    "stateMode": 1,
    "dateProduction": "2023-02-01T00:00:00",
    "erpOrderOrigin": "EIT",
    "isCheckStockBeforeOrder": false,
    "deleted": false,
    "comments": "EIT PROD",
    "fullUrl": "https://app.eitedelinternational.com",
    "hostDomain": "app.eitedelinternational.com",
    "hostIp": "127.0.0.1",
    "logoImg": "",
    "logoImgAltern": null,
    "logoText": null,
    "name": "EIT",
    "companyName": "EIT",
    "companyPublishManager": "Nom Prénom",
    "companyPublishForm": "SASU Société par actions simplifiée à associé unique",
    "companyRcs": "XXXXXXX",
    "companySiret": "XXXXXXX",
    "companyTva": "XXXXXXX",
    "htSite": true,
    "slogan": "EIT",
    "describeShort": "EIT",
    "describeFull": "EIT",
    "footerText": "EIT",
    "horaires": "Tous les vendredi de 8h à 18h",
    "metaTitle": "EIT",
    "metaDescribe": "EIT",
    "metaKeywords": "EIT",
    "metaSharedImage": null,
    "metaFavIcon": null,
    "metaAuthor": "EIT",
    "metaAppName": null,
    "metaSubject": "EIT",
    "analyticsKey": "000000",
    "linkedInAccount": null,
    "facebookAccount": "EIT",
    "instagramAccount": "EIT",
    "whatsappAccount": null,
    "twitterAccount": "EIT",
    "youtubeAccount": "EIT",
    "daylimotionAccount": "EIT",
    "twitchAccount": "EIT",
    "createDate": "2023-01-10T11:18:09.973",
    "createUserId": null,
    "createUserIp": null,
    "createUserBrowser": null,
    "updateDate": null,
    "updateUserId": null,
    "updateUserIp": null,
    "updateUserBrowser": null,
    "deletedDate": null,
    "deletedUserId": null,
    "deletedUserIp": null,
    "deletedUserBrowser": null,
    "personIdEcommerce": null,
    "personIdSav": null,
    "personId": null,
    "isImportMerchantExternalOrders": false,
    "isOrdersDataSourceFromErp": false,
    "sendOrdersToErpOn": true,
    "showOnlyMerchantBrands": true,
    "deliveryAddressModifyUrl": null,
    "erpCodeClientWeb": null,
    "isConsigneIncluded": true,
    "configuration": {
      "id": 18,
      "establishmentId": 20,
      "defaultTaxId": 1,
      "erpOrderOrigin": "EIT",
      "erpCodeClientWeb": null,
      "simultaneousUsersConnexion": 4,
      "isCheckStockBeforeOrder": true,
      "isOrdersDataSourceFromErp": false,
      "isImportMerchantExternalOrders": false,
      "deliveryAddressModifyUrl": null,
      "showOnlyMerchantBrands": true,
      "sendOrdersToErpOn": true,
      "isConsigneIncluded": true,
      "isDevis": null,
      "isWarehousemanResearch": false,
      "isVehicleMaintenance": false,
      "isManualRepair": null,
      "isTechnicalData": null,
      "isGraphicResearch": true,
      "isTechnicalCharacteristicsParts": false,
      "isProfessionalAdvice": false,
      "isShop": true,
      "isCart": true,
      "isPneumatic": true,
      "showAvailabilityStock": true,
      "showQuantityStock": true,
      "sessionTime": 24,
      "showBranchName": true,
      "showAutopartsproLogo": true,
      "isDiscount": null,
      "isPublicPrices": null,
      "smtpMainId": null,
      "smtpQuoteId": null,
      "technicalDataLicenceType": "4",
      "technicalDataLicenceDuration": "M",
      "warningMaintenanceText": null,
      "tax": {
        "id": 1,
        "countryId": 1001,
        "title": "20%",
        "percentage": 20,
        "isActive": true
      },
      "mainSmtp": null,
      "quoteSmtp": null
    },
    "phone": "0388001284",
    "email": "eit@eitedelinternational.com"
  },
  "suppliers": [
    {
      "id": 1,
      "typeId": 2,
      "typeTitle": "Catalogue pièce auto",
      "title": "TECDOC Pegasus 3.0",
      "description": "Webservice TECDOC Pegasus 3.0",
      "catalog": null,
      "frontCallEndPoint": "https://wstdc.autopartspro.fr",
      "devFrontCallEndPoint": "https://wstdc.autopartspro.fr",
      "dateCreated": "2023-10-13T17:10:28.923",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 2,
      "typeId": 3,
      "typeTitle": "Données techniques",
      "title": "TECRMI",
      "description": "Webservice TecRMI",
      "catalog": null,
      "frontCallEndPoint": "https://wsrmi.autopartspro.fr",
      "devFrontCallEndPoint": "https://dev-wsrmi.autopartspro.fr",
      "dateCreated": "2023-10-13T17:10:54.1",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 4,
      "typeId": 4,
      "typeTitle": "ERP",
      "title": "STARIS",
      "description": "Webservice Staris",
      "catalog": null,
      "frontCallEndPoint": "https://wserp2.autopartspro.fr",
      "devFrontCallEndPoint": "https://dev-wserp2.autopartspro.fr",
      "dateCreated": "2023-10-13T17:12:04.193",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 13,
      "typeId": 5,
      "typeTitle": "SIV (Plaques)",
      "title": "AAA Slvin",
      "description": "Webservice AAA Slvin",
      "catalog": null,
      "frontCallEndPoint": "https://wspl.autopartspro.fr",
      "devFrontCallEndPoint": "https://wspl.autopartspro.fr",
      "dateCreated": "2023-10-18T17:24:02.56",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 13,
      "typeId": 5,
      "typeTitle": "SIV (Plaques)",
      "title": "AAA Slvin",
      "description": "Webservice AAA Slvin",
      "catalog": null,
      "frontCallEndPoint": "https://wspl.autopartspro.fr",
      "devFrontCallEndPoint": "https://wspl.autopartspro.fr",
      "dateCreated": "2023-10-18T17:24:02.56",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 11,
      "typeId": 6,
      "typeTitle": "Service de l'application",
      "title": "ShoppingCart",
      "description": "Panier et commandes",
      "catalog": null,
      "frontCallEndPoint": "https://wscart.autopartspro.fr",
      "devFrontCallEndPoint": "https://wscart.autopartspro.fr",
      "dateCreated": "2024-02-01T00:00:00",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    }
  ],
  "terms": [],
  "links": {
    "cdnUrl": "https://cdn.autopartspro.fr"
  },
  "themeApp": {
    "light": {
      "mainColor": "#EB2431",
      "secondaryColor": "#111521",
      "terciaryColor": "#FFE073",
      "neutral23": "#3B393D",
      "shades76": "#FFE9EA",
      "neutral45": "#727272",
      "neutral71": "#B5B5B5",
      "neutral77": "#ffffff",
      "shades94": "#F0FAF8",
      "borderBtnWhite": "#DDE2E8",
      "btnSearch": "#EB2431",
      "btnLinear": "#EB2431",
      "background97": "#F9F8F9",
      "roundedBorder": "0.5rem",
      "dispo": "#4FAE40",
      "dispoSoon": "#FFE073",
      "indispo": "#DDE2E8",
      "defaultFontColor": "#FFFFFF"
    },
    "dark": {}
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
