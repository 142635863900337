<template>
    <hr class="separator"/>
</template>
<style scoped lang="scss">
.separator {
    &.success{
        color: var(--success-color);
    }
    &.error{
        color: var(--error-color);
    }
    &.info{
        color: var(--info-color);
    }
}
</style>