import type {VehicleGenericInformations, VehiclePlateData} from "@/types/interfaces"
export default defineNuxtRouteMiddleware(async (to, from) => {
    const config = useRuntimeConfig()
    const { links, query, establishment } = useAppConfig();
    const vehicleGenericInformations = useCookie<VehicleGenericInformations>(config.public.cookies.vehicleGenericInformations)
    const vehiclePlateInformation = useCookie<VehiclePlateData>(config.public.cookies.vehiclePlateData)

    const establishmentConfiguration = establishment.configuration

    if((to.path === links.catalog || to.path === links.devis) && !vehicleGenericInformations.value) {
        return navigateTo({ path: links.dashboard, query: { action: query.actions.newVehicle}})
    }

    if((to.path === links.cart || to.path === links.order) && !establishmentConfiguration.isCart) return navigateTo(links.dashboard)
})