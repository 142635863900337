<template>  
  <div id="app">
    <NuxtLoadingIndicator />
    <NuxtLayout v-if="isLoaded">
      <ElementsToast/>
      <NuxtPage />
    </NuxtLayout> 
    <VehicleLoader v-else/>
  </div>
</template>

<script setup lang="ts">
import { useThemeColor } from "@/composables/useThemeColor.js";

const isLoaded = ref<boolean>(false);
const { themeApp } = useAppConfig();

onMounted(async() => {  
  useThemeColor(themeApp.light);
  isLoaded.value = true;
});
</script>

<style scoped>
#exampleModalLabel {
font-family: Inter;
color: red;
}
span {
font-family: Inter;
}
p {
font-family: Inter;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1.25rem;
color: #3B393D;
}
.imgError500 {
width: 60%;
}
.spinner-grow {
width: 3rem;
height: 3rem;
background-color: #F0FAF8;
}
</style>